.cursor-pointer {
  cursor: pointer;
}

.quill {
  height: 100%;
}

.ql-container {
  border: none !important;
}

.ql-toolbar {
  margin: 5px;
  border: none !important;
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}

.page-item.active .page-link {
  z-index: 0 !important;
}
